@import "modules/responsive-type.scss";



$primary: #ac3524;/* MAIN COLOR */
$secondary: #007999; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa;
$border-radius: 0px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;

// Set your maximum and minimum screen sizes.
$min_width: 320px;
$max_width: 1920px;

// Font sizes will range between the $min_font and $max_font.
$min_font: 15px;
$max_font: 30px;

// Change these values to change the "scale" between different headers
// (h1,h2,h3, etc.). Larger numbers = larger font-sizes. 

$mod_1: 1.2; // mobile
$mod_2: 1.5; // desktop


@import url('https://fonts.googleapis.com/css?family=Amatic+SC|Comfortaa|Inconsolata|Lora');

h1 {
    font-family: 'Lora',serif; 
}


h2 {
    font-family: 'Amatic SC',serif;
    letter-spacing: 1px;
}

h3,h4 {
    font-family: 'Amatic SC',serif;
    font-weight: bold;
    letter-spacing: 1px;
}

h4 {
    font-size: 30px;
    font-weight: normal;
    margin-bottom: 1em;
}

html, body {
    height: 100%;
}

html {
  @include fluid-type($min_width, $max_width, $min_font, $max_font);
}

p {
    font-size: 1rem;
}

h1 {  
  font-size: $mod_1*$mod_1*$mod_1*$mod_1 *1rem; 
  @include fluid-type($min_width, $max_width, $mod_1*$mod_1*$mod_1 *$min_font, $mod_2*$mod_2*$mod_2 *$min_font);
}
h2 {  
  font-size: $mod_1*$mod_1*$mod_1 *1rem; 
  @include fluid-type($min_width, $max_width, $mod_1*$mod_1*$mod_1 *$min_font, $mod_2*$mod_2*$mod_2 *$min_font);
}
h3 { 
  font-size: $mod_1*$mod_1 *1rem;
  @include fluid-type($min_width, $max_width, $mod_1*$mod_1 *$min_font, $mod_2*$mod_2 *$min_font);
}

body {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

.flash {
    display: none;
}

q,
blockquote {
    quotes: "“" "”";
} 

.navbarFixed {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;
}

/* Change autocomplete styles in WebKit */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill, 
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: 1px solid #0065dd;
    -webkit-text-fill-color: #0065dd;
    -webkit-box-shadow: 0 0 0px 1000px #000 inset;
    transition: background-color 5000s ease-in-out 0s;
}

nav.navbar {
    z-index: 1000;
    border: none;
    border-radius: $border-radius;

    .navbar-nav {
        li {
            @media (max-width: 767px) {
                text-align: center;
            }
        }
        >li>a {
            text-align: center;
            margin-top: 22px;
            display: flex;
            align-items: center;
            color: $wht;
            // text-shadow: 0px 0px 11px $primary;
            font-weight: bold;
            font-size: 24px;
            letter-spacing: 2px;
            font-family: 'Amatic SC',serif;
            @media (max-width: 767px) {
                margin-top: 0;
                padding: 6px 0px;
                display: inline-block;
                text-align: center;
                color: $blk;
            }
            &:focus,
            &:active {
                background: transparent;
                color: $blk;
                outline: 0;
            }
            &:hover {
                background: $primary;
                color: $wht;
                @media (max-width: 1024px) {
                    background: transparent;
                    color: $blk;
                    outline: 0;
                }
            }
        }
    }
}

.navbar-right {
    margin-top: 0px;
    margin-bottom: 0px;
    @media (max-width: 767px) {
        background: rgba($wht,0.85);
    }
}

.navbar-toggle {
    margin: 20px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;

}


.navbar-default {
    background:  linear-gradient(right,
        rgba($blk,0.5),
        rgba($primary,1),
        rgba($primary,0.5),
        rgba($wht,0.5),
        rgba($wht,1),
        rgba($blk,0.5)
        );
    
    background-size: cover;

        @media (max-width: 767px) {

            background:  linear-gradient(right,
            rgba($primary,0.75),
            rgba($primary,0.75),
            rgba($wht,0.5),
            rgba($blk,0.75),
            rgba($blk,0.75)
        );
        
        }

        .navbar-collapse {
            @media (max-width: 767px) {
                border: none;
            }
        }
}

/** LOGIN FORM **/

@mixin btn {
    border: none;
    border-radius: $border-radius;
    background: $primary;
    color: $wht;
    padding: 0.5em 2em;
    font-size: 1.25em;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    display: inline-block;
    margin: 2em 0em;
    transition: 0.7s ease all;
    &:hover {
        background: lighten($primary, 10%);
        color: lighten($wht, 10%);
        text-decoration: none;
        @media (max-width: 1024px) {
            background: $secondary;
            color: $wht;
            box-shadow: 0px 0px 5px $blk;
        }
    }
}



.btn-default {
    @include btn;
}
.modal-dialog {
    max-width: 500px;
    width: 100%;
    text-align: center;
    margin: 6em auto;

    .close {display: none;}

    .modal-content {
        color: $primary;

        h2 {
            text-align:center;
            font-size: 25px;
        }
    }

    // a.btn, a.btn-default, button {
    //     @include btn
    //     margin: 0;
    //     display: block;
    // }
}

.modal-header, .modal-footer {
    background: $primary;
    color: $wht;
}

.modal-open {
  overflow: auto;
  padding-right: 0px !important;
}



/** END LOGIN FORM **/

footer {
    padding: 50px 0px 20px;
    background: $primary;
    color: $footerLinks;
    font-size: 15px;
    a {
        color: $footerLinks;
        white-space: nowrap;
        font-size: 15px;
        @media (max-width: 990px) {
            font-size: 13px;
        }
        &:hover {
            color: lighten($footerLinks, 10%);
            outline: 0 !important;
            text-decoration: none;
        }
        &:focus {
            color: $wht;
            outline: 0 !important;
            text-decoration: none;
        }
    }
    p {
        font-size: 15px;
        @media (max-width: 990px) {
            font-size: 13px;
        }
    }
}

input#username,
input#password {
    width: 100%;
    @media (max-width: 1024px) {
        font-size: 16px;
    }
}

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}

a,
a:hover,
a:focus,
a:active {
    outline: 0 !important;
}

.logo {
    max-width: 250px;
    padding: 0.5em;
    filter: drop-shadow(2px 1px 1px $wht);
}

@media(max-width: 767px) {
    .logo {
        max-width: 180px;
        filter: drop-shadow(1px 0px 0px $wht);

    }
    .navbar-header a {
        float: left;
    }
}

@media (max-width: 600px) {
    .logo {
        max-width: 150px;
    }
    .navbar-toggle {
        margin-top: 14px;
    }
}

.top-pad {
    padding: 3em;
    background: white;

    @media (max-width: 990px) {
        padding: 1em;
    }    
}

.flex {
    display: flex;
    justify-content: center;
    align-items: center;
    @media  (max-width: 1024px) {
        display: block;
        /* may need to be inline-block */ 
    }
}

.terms,
.privacy {
    p {
        font-size: 1rem;
    }
    
    & ol {
        li {
            font-size: 1rem;
        }
        & ol li {
            font-size: 0.85rem;
        }
    }
}





.row.banner {
    background: linear-gradient(
    rgba(100,100,100,0.75),
    rgba(100,100,100,0.75)
    ),
    url(../img/banner.jpg) no-repeat top;
    background-size: cover;
    background-attachment: fixed;
    padding: 15em 0em 12em;
    text-shadow: 1px 1px 3px $blk;
    color: $wht;
    margin-top: -7em;

    position: relative;
    z-index: 5;

    clip-path: polygon(0% 0%, 1000% 0%, 100% 90%, 50% 100%, 0% 90%);


    h1 { 
        font-size: 4em;
        text-transform: uppercase; 
        span {
            color: $primary;
            font-style: italic;
            text-shadow: 1px 1px 0px $wht;
        }

        @media (max-width: 990px) {
            font-size: 2.5em;
        }

        @media (max-width: 420px) {
            font-size: 2em;
        }        
    }

    h2 {
        font-size: 3.5em;
        font-style: italic;

        span {
            color: $primary;
            font-style: italic;
            font-weight: bold;
            text-shadow: 1px 1px 0px $wht;
        }

        @media (max-width: 990px) {
            font-size: 2.5em;
        }

        @media (max-width: 420px) {
            font-size: 1.5em;
        }         
    }

    @media (max-width: 1024px) {
        background-attachment: scroll;
    }
    
    @media (max-width: 990px) {
        padding: 10em 2em 7em;
    }
}

/*
hr {
    max-width: 290px;
    width: 100%;
    border:2px solid $wht;
    border-radius: 10px;
}
*/

.sectionCTA {
    margin-top: -6em;
    padding: 9em 0em 3em;
}

hr {
    width: 10em;
    border-top: 1px solid $primary;
    border-bottom: 1px solid darken($primary,10%);
}

.primaryBgLt {
    background: lighten($primary, 30%);
    padding: 3em 0em;
}

.imgBlock {
    padding: 0em;
    // margin-top: -6em;
    position: relative;
    z-index: 1;

    @media (max-width: 767px) {
        height: auto;
    }
}



@mixin btn1 {
    font-size: 16px;
    font-weight: bold;
    background: rgba($wht,1);
    color: $primary;
    text-align: center;
    // border: 1px solid darken($primary, 15%);
    border: none;
    margin: 0 auto;
    padding: 1em 2em;
    display: inline-block;

    &:hover {
        background: white;
        color: $primary;
        box-shadow: 0px 0px 15px lighten($blk,10%);
        text-decoration: none;
    }
}

.btn {
    @include btn1;
    border-radius: 0px;
}


.primaryBg {
        background:  linear-gradient(
        rgba($primary,0.65),
        rgba($blk,0.85),
        rgba($primary,0.9)
        ),
        url(../img/galaxy.jpg) no-repeat center;
    // background: $primary;
    color: white;
    // text-shadow: 0px 1px 0px $blk;

    hr {
        border-color: $wht;
    }
}

.bgImg {
    background:  linear-gradient(
        rgba(200,200,200,0.75),
        rgba(255,255,255,1)
        ),
        url(../img/banner3.jpg) no-repeat top;
    background-size: cover;
    padding: 6em 0em;
    position: relative;
    z-index: 1;

    text-shadow: 0px 0px 10px white;


    @media (max-width: 767px) {
        padding: 4em 0em;
    }


    @media (max-width: 420px) {
        padding: 1em 0em;
    }    
}

.overlay {
   position:absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: url(../img/bg3.png) repeat center;
    z-index: 2;
}

.miniform,h1,img {
    position: relative;
    z-index: 5;
}


#contact {
    z-index: 400;
}

#services {
    h3 { 
    padding: 3em 0em;
    line-height: 1.6em;
        @media (max-width: 767px) {
            padding: 0.5em 0em;
        }
    }
}